var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 900,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        okButtonProps: { props: { disabled: _vm.disabled } },
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "msgCategory",
                    label: "消息类型"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { disabled: _vm.disableSubmit },
                      model: {
                        value: _vm.model.msgCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "msgCategory", $$v)
                        },
                        expression: "model.msgCategory"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: "1" } }, [
                        _vm._v("通知公告")
                      ]),
                      _c("a-radio", { attrs: { value: "2" } }, [
                        _vm._v("系统消息")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "titile",
                    label: "标题"
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入标题",
                      readOnly: _vm.disableSubmit
                    },
                    model: {
                      value: _vm.model.titile,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "titile", $$v)
                      },
                      expression: "model.titile"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "msgAbstract",
                    label: "摘要"
                  }
                },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入摘要" },
                    model: {
                      value: _vm.model.msgAbstract,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "msgAbstract", $$v)
                      },
                      expression: "model.msgAbstract"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "endTime",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "endTime",
                    label: "截至日期"
                  }
                },
                [
                  _c("j-date", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      getCalendarContainer: function(node) {
                        return node.parentNode
                      },
                      placeholder: "请选择结束时间",
                      showTime: "",
                      dateFormat: "YYYY-MM-DD HH:mm:ss"
                    },
                    model: {
                      value: _vm.model.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "endTime", $$v)
                      },
                      expression: "model.endTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "msgType",
                    label: "接收用户"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { disabled: _vm.disableSubmit },
                      on: { change: _vm.chooseMsgType },
                      model: {
                        value: _vm.model.msgType,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "msgType", $$v)
                        },
                        expression: "model.msgType"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: "USER" } }, [
                        _vm._v("指定用户")
                      ]),
                      _c("a-radio", { attrs: { value: "ALL" } }, [
                        _vm._v("全体用户")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.userType
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "指定用户"
                      }
                    },
                    [
                      _c("j-select-multi-user", {
                        attrs: {
                          returnKeys: _vm.returnKeys,
                          placeholder: "请选择指定用户",
                          "trigger-change": true
                        },
                        model: {
                          value: _vm.userIds,
                          callback: function($$v) {
                            _vm.userIds = $$v
                          },
                          expression: "userIds"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "优先级"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: {
                        placeholder: "请选择优先级",
                        disabled: _vm.disableSubmit
                      },
                      model: {
                        value: _vm.model.priority,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "priority", $$v)
                        },
                        expression: "model.priority"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: "L" } }, [_vm._v("低")]),
                      _c("a-radio", { attrs: { value: "M" } }, [_vm._v("中")]),
                      _c("a-radio", { attrs: { value: "H" } }, [_vm._v("高")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "j-field-content",
                  attrs: {
                    labelCol: _vm.labelColX1,
                    wrapperCol: _vm.wrapperColX1,
                    label: "内容"
                  }
                },
                [
                  _c("j-editor", {
                    model: {
                      value: _vm.model.msgContent,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "msgContent", $$v)
                      },
                      expression: "model.msgContent"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }