import { render, staticRenderFns } from "./PermissionList.vue?vue&type=template&id=9e27c1dc&"
import script from "./PermissionList.vue?vue&type=script&lang=js&"
export * from "./PermissionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/admin/ylpp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9e27c1dc')) {
      api.createRecord('9e27c1dc', component.options)
    } else {
      api.reload('9e27c1dc', component.options)
    }
    module.hot.accept("./PermissionList.vue?vue&type=template&id=9e27c1dc&", function () {
      api.rerender('9e27c1dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/examples/list/PermissionList.vue"
export default component.exports